// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #ebf1ff;
}
div.table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
div.table::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

/* Handle on hover */
div.table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tracker/TableData.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB","sourcesContent":[".table {\n  max-height: 400px;\n  overflow-y: scroll;\n  border: 1px solid #ebf1ff;\n}\ndiv.table::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n/* Handle */\ndiv.table::-webkit-scrollbar-thumb {\n  background: #e0e0e0;\n  border-radius: 5px;\n}\n\n/* Handle on hover */\ndiv.table::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
