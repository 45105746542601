// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `math-field::part(virtual-keyboard-toggle) {
  color: red;
}

math-field::part(container) {
  height: 100% !important;
}

math-field::part(content) {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

math-field::part(virtual-keyboard-toggle) {
  display: none;
}

math-field::part(menu-toggle) {
  display: none;
}

body > .ML__keyboard {
  z-index: 999999;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Courses/Maths/playground.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":["math-field::part(virtual-keyboard-toggle) {\n  color: red;\n}\n\nmath-field::part(container) {\n  height: 100% !important;\n}\n\nmath-field::part(content) {\n  display: flex;\n  justify-content: center !important;\n  align-items: center;\n}\n\nmath-field::part(virtual-keyboard-toggle) {\n  display: none;\n}\n\nmath-field::part(menu-toggle) {\n  display: none;\n}\n\nbody > .ML__keyboard {\n  z-index: 999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
