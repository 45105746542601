// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTypography-body1 {
  font-size: 21px !important; }

::-webkit-scrollbar {
  width: 10px; }
`, "",{"version":3,"sources":["webpack://./src/Components/SidebarComponent/SidebarComponent.scss"],"names":[],"mappings":"AAAA;EACE,0BAA0B,EAAA;;AAE5B;EACE,WAAW,EAAA","sourcesContent":[".MuiTypography-body1 {\n  font-size: 21px !important;\n}\n::-webkit-scrollbar {\n  width: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
